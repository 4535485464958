import { create } from 'zustand'

export const useAuthStore = create((set) => ({
  authToken: null,
  user: null,
  // deleteEverything: () => set({}, true), // clears the entire store, actions included
  // //   increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
  // //   removeAllBears: () => set({ bears: 0 }),
  setAuthToken: (authToken) => set((state) => ({ authToken: authToken })),

  // login: async (username, password) => {
  //   set((state) => {
  //     state.authToken = username
  //     console.log('hhuhu', state)
  //   })
  //   console.log('CHAY ')
  // },
}))
