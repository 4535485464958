import React, { useRef, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import localStorage from 'localStorage'

const ProtectedRoute = (props) => {
  const { children } = props

  let location = useLocation()
  const dispatch = useDispatch()

  const authToken = useSelector((state) => state.authToken)
  const localAuthToken = localStorage.getItem('authToken')

  if (localAuthToken) {
    dispatch({ type: 'login', authToken: localAuthToken })
  }

  // Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
  useEffect(() => {
    console.log('?????')
    console.log('==================')
    console.log('AUTH DAY HA:', authToken, localAuthToken)
  }, [])

  if (localAuthToken == null) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children
}

export default ProtectedRoute
