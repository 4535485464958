import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser, cilXCircle } from '@coreui/icons'
import axios from 'axios'
import localStorage from 'localStorage'

const Login = () => {
  const baseURL = process.env.REACT_APP_API_URL

  const [userAccount, setUserAccount] = useState({
    email: '',
    password: '',
  })

  const [errorMessage, setErrorMessage] = React.useState("");
  const dispatch = useDispatch()
  const authToken = useSelector((state) => state.authToken)
  const navigate = useNavigate()

  function handleChange(event) {
    const { id, value } = event.target
    setUserAccount((preState) => ({
      ...preState,
      [id]: value,
    }))
  }

  function handleSubmit(event) {
    event.preventDefault()

    axios
      .post(`${baseURL}/auth/login`, {
        email: userAccount.email,
        password: userAccount.password,
      })
      .then((res) => {
        dispatch({ type: 'login', authToken: res.data.data.accessToken })
        localStorage.setItem('authToken', res.data.data.accessToken)

        console.log(authToken)
        navigate('/dashboard')
      })
      .catch((err) => {
        console.log(err)
        setErrorMessage(err.message)
      })
  }

  // useEffect(() => {
  //   console.log('chay')
  //   console.log('==================')
  //   console.log(authToken)
  // }, [authToken])

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    {errorMessage && <CAlert color="danger" className='d-flex'>
                      <CIcon icon={cilXCircle} size="xl" />
                      <span className='px-1'>{errorMessage}</span>
                    </CAlert>}
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        onChange={(event) => handleChange(event)}
                        id="email"
                        placeholder="Email"
                        autoComplete="email"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        onChange={(event) => handleChange(event)}
                        type="password"
                        id="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          onClick={(event) => handleSubmit(event)}
                          color="primary"
                          className="px-4"
                        >
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
